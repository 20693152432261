import axios from "axios";
import VueCookies from "vue-cookies";
import Vue from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import router from "@/router/index";
import qs from "qs";
Vue.use(VueCookies);

const token = $cookies.get("myneighborBackendToken");

const withoutTokenRequest = axios.create({
  baseURL: `${process.env.VUE_APP_API}`,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    "Access-Control-Allow-Origin": "*",
  },
});

const request = axios.create({
  baseURL: `${process.env.VUE_APP_API}`,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    // Authorization: `Bearer ${token}`,
  },
});

// 客服暱稱
// phone 0999800800
// id IJJ99199
// productID 723
const CS_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiMDk5OTgwMDgwMCIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6Ik1lbWJlciIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL3NpZCI6IjA5OTk4MDA4MDAiLCJuYmYiOjE2NzU2NTE0NjEsImV4cCI6MTcwNzE4NzQ2MSwiaXNzIjoiSW50ZXJzZW5zZSIsImF1ZCI6Ik15TmVpZ2hib3JBUEkifQ.dNYFYjJpCYbJE9OMMMYuFSW7H6c--s9y9HJ76NqRKSk";

// 客人

const CM_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiMDkxMDQ4MjQ1MiIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6Ik1lbWJlciIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL3NpZCI6IjA5MTA0ODI0NTIiLCJuYmYiOjE2NzU2NTE3ODksImV4cCI6MTcwNzE4Nzc4OSwiaXNzIjoiSW50ZXJzZW5zZSIsImF1ZCI6Ik15TmVpZ2hib3JBUEkifQ.l9eZfGA1NmXOIXewtk_JSiw48WtfNkThKlshSoP8Qsk";
const chatRequest = axios.create({
  baseURL: `${process.env.VUE_APP_API}`,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: `Bearer ${CS_TOKEN}`,
  },
});

request.interceptors.request.use((config) => {
  const token = $cookies.get("myneighborBackendToken");
  if (token) {
    // config.baseURL = `${process.env.VUE_APP_API}`;
    config.headers["Authorization"] = `Bearer ${token}`;
    // config.headers["Content-Type"] = "application/x-www-form-urlencoded";
  }
  return config;
});

// Error Handle
const errorHandle = (status, res) => {
  switch (status) {
    case 400:
      console.log("400");

      Swal.fire({
        icon: "error",
        title: "請求錯誤",
        text: res.data.Message,
        confirmButtonText: "確定",
        confirmButtonColor: "#ff6666",
      });

      break;
    case 401:
      console.log("401");
      Swal.fire({
        icon: "error",
        title: "帳號驗證失敗，請重新登入",
        confirmButtonText: "確定",
        confirmButtonColor: "#ff6666",
      }).then(() => {
        $cookies.remove("myneighborBackendToken");
        localStorage.removeItem("myneighborBackendAccount");
        localStorage.removeItem("myneighborBackendName");
        localStorage.removeItem("myneighborBackendIsLogin");
        router.push("/login");
      });
      break;
    case 500:
      console.log("500");
      Swal.fire({
        icon: "error",
        title: "伺服器連線錯誤，請稍後再試",
        confirmButtonText: "確定",
        confirmButtonColor: "#ff6666",
      });
      break;
    default:
      console.log(status);
      Swal.fire({
        icon: "error",
        title: "帳號驗證失敗，請重新登入",
        confirmButtonText: "確定",
        confirmButtonColor: "#ff6666",
      }).then(() => {
        $cookies.remove("myneighborBackendToken");
        localStorage.removeItem("myneighborBackendAccount");
        localStorage.removeItem("myneighborBackendName");
        localStorage.removeItem("myneighborBackendIsLogin");
        router.push("/login");
      });
      break;
  }
};

// const errorHandle = () => {
//   Swal.fire({
//     icon: "error",
//     title: "帳號驗證失敗，請重新登入",
//     confirmButtonText: "確定",
//     confirmButtonColor: "#ff6666",
//   }).then(() => {
//     $cookies.remove("myneighborBackendToken");
//     localStorage.removeItem("myneighborBackendAccount");
//     localStorage.removeItem("myneighborBackendName");
//     localStorage.removeItem("myneighborBackendIsLogin");
//     router.push("/login");
//   });
// };

request.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { response } = error;
    if (response) {
      if (response.data.Message === "無此對話窗") {
        const data = qs.stringify({
          ProductID: "-80",
          AskerUserID: qs.parse(response.config.data).AskerUserID,
        });
        apiCreateChatRoom(data).then((res) => {
          if (res.data.Message === "Success") {
            console.log("新聊天室");
          }
        });
      } else {
        errorHandle(response.status, response);
      }
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

// loginGetToken
export const apiLoginToken = (data) =>
  withoutTokenRequest.post("/WebGetToken", data);

// Member
export const apiMemberAll = (data) => request.post("/WebAccountManage", data);

// User
export const apiUserList = (data) => request.post("/GetWebUser", data);
export const apiUserData = (data) => request.post("/GetWebUserDetail", data);
export const apiChangeUserStatus = (data) =>
  request.post("/ModifyWebUserDetail", data);
export const apiSearchUser = (data) => request.post("/GetWebUser", data);

// Impeach
export const apiImpeachList = () => request.post("/WebImpeachList");
export const apiSearchImpeach = (data) => request.post("/WebImpeachList", data);
export const apiImpeachData = (data) =>
  request.post("/GetWebImpeachDetail", data);
export const apiEditImpeach = (data) =>
  request.post("/ModifyImpeachApprove", data);

// Product
export const apiProductList = (data) => request.post("/GetWebProduct", data);
export const apiProductData = (data) =>
  request.post("/GetWebProductDetail", data);
export const apiChangeProductStatus = (data) =>
  request.post("/ModifyWebProductDetail", data);

// Location
export const apiLocationList = () =>
  withoutTokenRequest.get("/GetLocationList");

// Contact
export const apiContactList = () => request.post("/WebContactUsList");
export const apiContactData = (data) => request.post("/WebContactUsList", data);
export const apiChangeContactStatus = (data) =>
  request.post("/WebModifyContactUs", data);
export const apiSearchContact = (data) =>
  request.post("/WebContactUsList", data);

// Activity
export const apiActivityList = () => request.post("/WebListAnnouncement");
export const apiSearchActivity = (data) =>
  request.post("/WebListAnnouncement", data);
export const apiAddActivity = (data) =>
  request.post("/WebCreateAnnouncement", data);
export const apiEditActivity = (data) =>
  request.post("/WebEditAnnouncement", data);

export const apiDeleteActivity = (data) =>
  request.post("/WebDeleteAnnouncement", data);

// Report - product
export const apiProductReport = (data) => request.post("/ReportProduct", data);
export const apiProductReportExcel = (data) =>
  request.post("/ReportProductExcel", data, { responseType: "blob" });

// Report - User
export const apiUserReport = (data) => request.post("/ReportAccount", data);
export const apiUserReportExcel = (data) =>
  request.post("/ReportAccountExcel", data, { responseType: "blob" });

// Report - Date
export const apiSearchDateReport = (data) =>
  request.post("/ReportDateOverview", data);
export const apiSearchDateReportExcel = (data) =>
  request.post("/ReportDateOverviewExcel", data, { responseType: "blob" });

// Report - All
export const apiAllReport = () => request.get("/ReportOverview");

// ChatRoom
// 第一次進到聊天室
export const apiGetChatRoomList = () => request.get("/GetListChatSimplify");
// 帶starttime參數
export const apiGetChatRooms = (data) =>
  request.post("/GetListChatSimplify", data);
export const apiGetChatMessage = (data) =>
  request.post("/GetChatAndMessage", data);
export const apiSendChatMessage = (data) =>
  request.post("/CreateMessage", data);
export const apiChangeChatMessageRead = (data) =>
  request.post("/SetChatMessageRead", data);
export const apiGetAccountInfo = () => chatRequest.post("/GetAccountInfo");
export const apiSendChatImage = (data) =>
  request.post("/CreateImageMessage", data);
// 創建聊天室
export const apiCreateChatRoom = (data) => request.post("/CreateChat", data);

// Organizes
export const apiGetOrganizeList = (data) =>
  request.post("v3/GetOrganizeList", data);
export const apiEditOrganize = (data) => request.post("v3/EditOrganize", data);
export const apiGetOrganizeData = (data) =>
  request.post("v3/GetOrganizeDetail", data);
export const apiGetNeighborUserList = (data) =>
  request.post("v3/GetNeighborUserList", data);

// UserOrganizes
export const apiEditUserOrganize = (data) =>
  request.post("v3/EditUserOrganize", data);

// Neighbor
export const apiGetListNeighborByGroup = (data) =>
  request.post("v3/GetListNeighborByGroup", data);

export const apiGetListNeighbor = (data) =>
  request.post("v3/GetListNeighbor", data);

// Ad
export const apiGetListAd = (data) => request.post("/GetListAd", data);
export const apiAdData = (data) => request.post("/GetAd", data);
export const apiAddAd = (data) => request.post("/CreateAd", data);
export const apiEditAd = (data) => request.post("/UpdateAd", data);
export const apiGetListLocationAd = (data) =>
  request.post("/GetListLocationAd", data);
