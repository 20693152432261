const DB_NAME = "MYNEIGHBOR_MESSAGEDB";
const DB_VERSION = 1;
let DB;

export default {
  // 建立、取得DB
  async getDb() {
    console.log("Chat DB");
    return new Promise((resolve, reject) => {
      if (DB) {
        return resolve(DB);
      }
      let request = window.indexedDB.open(DB_NAME, DB_VERSION);
      request.onerror = (e) => {
        console.log("Error opening db", e);
        reject("Error");
      };
      request.onsuccess = (e) => {
        DB = e.target.result;
        resolve(DB);
      };

      request.onupgradeneeded = (e) => {
        let db = e.target.result;
        const list = [
          {
            name: "chatInfo",
            key: "AskerUserID",
          },
          { name: "message", key: "MessageID" },
        ];
        list.forEach((data) => {
          db.createObjectStore(data.name, {
            autoIncrement: true,
            keyPath: "id",
          }).createIndex(data.key, data.key, {
            unique: true,
          });
        });
      };
    });
  },

  async deleteCat(cat) {
    let db = await this.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["chatInfo"], "readwrite");
      trans.oncomplete = () => {
        resolve();
      };

      let store = trans.objectStore("chatInfo");
      store.delete(cat.id);
    });
  },

  // 更新ChatInfo
  async updateChatRoom() {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["chatInfo"], "readonly");
      trans.oncomplete = () => {
        resolve(chatroom);
      };

      let store = trans.objectStore("chatInfo");
      let chatroom = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          chatroom.push(cursor.value);
          cursor.continue();
        }
      };
    });
  },

  async getMessage() {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["message"], "readonly");
      trans.oncomplete = () => {
        resolve(message);
      };

      let store = trans.objectStore("message");
      let message = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          message.push(cursor.value);
          cursor.continue();
        }
      };
    });
  },

  // 取得聊天室列表
  async getChatRoomList() {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["chatInfo"], "readonly");
      trans.oncomplete = () => {
        resolve(chatroom);
      };

      let store = trans.objectStore("chatInfo").getAll();
      let chatroom = [];

      store.onsuccess = (e) => {
        chatroom = store.result;
        return chatroom;
        // console.log(e.target.result);
        // let cursor = e.target.result;
        // if (cursor) {
        //   chatroom.push(cursor.value);
        //   cursor.continue();
        // }
      };
    });
  },

  // 新增Message
  async addMessage(message) {
    let db = await this.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["message"], "readwrite");
      trans.oncomplete = () => {
        resolve();
      };

      let store = trans.objectStore("message");
      // store.add(message);
    });
  },

  async updateChatRoom() {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["chatInfo"], "readwrite");
      trans.oncomplete = () => {
        resolve(chatroom);
      };

      let store = trans.objectStore("chatInfo");
      let chatroom = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          chatroom.push(cursor.value);
          cursor.continue();
        }
      };
    });
  },

  // 取得聊天記錄列表
  async getMessageList() {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["message"], "readonly");
      trans.oncomplete = () => {
        resolve(messages);
      };

      let store = trans.objectStore("message").getAll();
      let messages = [];

      store.onsuccess = (e) => {
        messages = store.result;
        return messages;
      };
    });
  },

  async getMessage() {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["message"], "readwrite");
      trans.oncomplete = () => {
        resolve(msg);
      };

      let store = trans.objectStore("message");
      let msg = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          msg.push(cursor.value);
          cursor.continue();
        }
      };
    });
  },

  async updateSelectChatRoom(chatroom) {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["chatInfo"], "readwrite");
      trans.oncomplete = () => {
        resolve(chatroom);
      };

      let store = trans.objectStore("chatInfo");
      store.put(chatroom);
    });
  },

  async updateSelectMessage(message) {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["message"], "readwrite");
      trans.oncomplete = () => {
        resolve(message);
      };

      let store = trans.objectStore("message");
      store.put(message);
    });
  },

  async updateMessage() {
    let db = await this.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["message"], "readwrite");
      trans.oncomplete = () => {
        resolve(msg);
      };

      let store = trans.objectStore("message");
      let msg = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          msg.push(cursor.value);
          cursor.continue();
        }
      };
    });
  },

  async addChatRoom(chatroom) {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["chatInfo"], "readwrite");
      trans.oncomplete = () => {
        resolve();
      };
      let store = trans.objectStore("chatInfo");
      store.add(chatroom);
    });
  },

  async addMessage(message) {
    let db = await this.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["message"], "readwrite");
      trans.oncomplete = () => {
        resolve();
      };
      let store = trans.objectStore("message");
      store.add(message);
    });
  },

  async saveMessage(message) {
    let db = await this.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["message"], "readwrite");
      trans.oncomplete = () => {
        resolve();
      };
      let store = trans.objectStore("message");

      message.forEach((data) => {
        store.add(data);
      });
    });
  },
};
