import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/login",
      name: "Login",
      component: () => import("@/components/Login"),
    },

    {
      path: "/NotFound",
      component: () => import("@/components/NotFound"),
      hidden: true,
    },

    {
      path: "/",
      redirect: "/dashboard",
    },

    {
      path: "/dashboard",
      name: "Dashboard",
      component: () => import("@/components/Dashboard"),
      meta: {
        requiresAuth: true,
      },

      children: [
        {
          path: "products",
          name: "Products",
          component: () => import("@/components/Products/Products"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "products/:id",
          name: "Product",
          component: () => import("@/components/Products/Product/Product"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "users",
          name: "Users",
          component: () => import("@/components/Users/Users"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "users/:id",
          name: "User",
          component: () => import("@/components/Users/User/User"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "impeachs",
          name: "Impeachs",
          component: () => import("@/components/Impeachs/Impeachs"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "impeachs/:id",
          name: "Impeach",
          component: () => import("@/components/Impeachs/Impeach/Impeach"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "report",
          name: "Report",
          component: () => import("@/components/Report/Report"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "contacts",
          name: "Contacts",
          component: () => import("@/components/Contacts/Contacts"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "ads",
          name: "Ads",
          component: () => import("@/components/Ads/Ads"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "ads/:id",
          name: "Ad",
          component: () => import("@/components/Ads/Ad/Ad"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "ads/ad/addad",
          name: "AddAd",
          component: () => import("@/components/Ads/AddAd"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "chatrooms",
          name: "ChatRooms",
          component: () => import("@/components/ChatRooms/ChatRooms"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "chatrooms/:id",
          name: "ChatRoom",
          component: () => import("@/components/ChatRooms/ChatRoom/ChatRoom"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "contacts/:id",
          name: "Contact",
          component: () => import("@/components/Contacts/Contact/Contact"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "activites",
          name: "Activities",
          component: () => import("@/components/Activities/Activities"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "activites/:id",
          name: "Activity",
          component: () => import("@/components/Activities/Activity/Activity"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "activites/activity/addactivity",
          name: "AddActivity",
          component: () => import("@/components/Activities/AddActivity"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "members",
          name: "Members",
          component: () => import("@/components/Members/Members"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "members/:id",
          name: "Member",
          component: () => import("@/components/Members/Member/Member"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "members/member/addmember",
          name: "AddMember",
          component: () => import("@/components/Members/AddMember"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "organizes",
          name: "Organizes",
          component: () => import("@/components/Organizes/Organizes"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "organizes/:id",
          name: "Organize",
          component: () => import("@/components/Organizes/Organize/Organize"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "organizes/organize/addorganize",
          name: "AddOrganize",
          component: () => import("@/components/Organizes/AddOrganize"),
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },

    { path: "*", redirect: "/NotFound", hidden: true },
  ],
});
