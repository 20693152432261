<template>
  <div class="flex justify-center items-center min-h-screen">
    <div class="flex flex-col items-center w-96 bg-white rounded h-full p-10">
      <div
        class="rounded-full w-44 h-44 bg-main flex justify-center items-center"
      >
        <img class="object-contain w-24" src="../assets/logo.png" alt="" />
      </div>
      <div class="text-2xl font-semibold pt-4">厝邊管理後台</div>
      <div class="pt-10 w-11/12">
        <div class="flex flex-col justify-between">
          <label class="block text-left"> 帳號 </label>
          <input
            v-model.trim="account"
            type="account"
            name="account"
            v-validate="'required'"
            :class="{ 'is-invalid': errors.has('account') }"
            class="mt-1 px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 block w-full rounded"
          />
          <span
            class="text-red-500 mt-2 text-left"
            v-show="errors.has('account')"
            >請輸入帳號</span
          >
        </div>
        <div class="flex flex-col justify-between pt-4">
          <label class="block text-left"> 密碼 </label>
          <input
            type="password"
            v-model.trim="password"
            name="password"
            v-validate="'required'"
            :class="{ 'is-invalid': errors.has('password') }"
            class="mt-1 px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 block w-full rounded"
          />
          <span
            class="text-red-500 mt-2 text-left"
            v-show="errors.has('password')"
            >請輸入密碼</span
          >
        </div>
        <div class="flex flex-col justify-between pt-10">
          <button
            @click="login"
            class="mt-1 px-3 mr-2 py-2 bg-main text-white border border-main block w-full rounded"
          >
            登入
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "Login",
  data() {
    return {
      account: "",
      password: "",
    };
  },
  methods: {
    login() {
      this.$validator.validate().then((result) => {
        if (result) {
          const data = {
            intMode: 1,
            Account: this.account,
            Password: this.password,
          };
          this.$store.dispatch("login", data);
        } else {
          Swal.fire({
            icon: "error",
            title: "請輸入帳號和密碼",
            confirmButtonText: "確定",
            confirmButtonColor: "#ff6666",
          });
        }
      });
      // var axios = require("axios");
      // var qs = require("qs");
      // var data = qs.stringify({
      //   Account: "test",
      //   Password: "MTExMTEx",
      // });
      // var config = {
      //   method: "post",
      //   url: "http://34.81.168.79:9001/api/MyNeighborAPI/WebGetToken",
      //   headers: {
      //     "Content-Type": "application/x-www-form-urlencoded",
      //   },
      //   data: data,
      // };
      // axios(config)
      //   .then(function (response) {
      //     console.log(JSON.stringify(response.data));
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //   });
    },
  },
};
</script>
