import Vue from "vue";
import App from "./App.vue";
import "./app.css";
import "./datepicker.scss";
import router from "./router";
import VueCookies from "vue-cookies";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import VeeValidate from "vee-validate";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
// import VueToast from "vue-toast-notification";
// import "vue-toast-notification/dist/theme-sugar.css";
import qs from "qs";
Vue.prototype.$qs = qs;
Vue.component("Loading", Loading);

Vue.use(VeeValidate, {
  events: "input|blur",
});

import store from "./store";
Vue.use(VueCookies);
// Vue.use(VueToast);

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  const tokenExists = $cookies.get("myneighborBackendToken");

  if (to.meta.requiresAuth) {
    if (tokenExists) {
      next();
    } else {
      next({
        path: "/login",
      });
    }
  } else {
    if (to.path == "/login" && tokenExists) {
      next("/");
    } else {
      next();
    }
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
